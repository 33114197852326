<template>
  <div class="container">
    <div class="logo">
      <img src="@/assets/nhlogin/nhlogo.png" alt />
    </div>

    <div v-if="httptype=='qd'" class="loginform">
      <md-field>
        <md-input-item
          type="text"
          v-model="phoneorempno"
          placeholder="请输入工号"
          @change="phoneorempno=$transform.transform(phoneorempno)"
          clearable
        ></md-input-item>
        <md-input-item v-model="password" type="password" placeholder="首次登录密码为身份证后6位" clearable></md-input-item>
        <md-button :loading="btnloading" @click="nhlogin" :inactive="btnloading">登录</md-button>
      </md-field>
    </div>

    <div v-else class="loginform">
      <md-field>
        <md-input-item
          v-if="page === 'login'"
          type="text"
          v-model="phoneorempno"
          placeholder="手机号或者工号"
          clearable
          @change="phoneorempno=$transform.transform(phoneorempno)"
        ></md-input-item>
        <md-input-item
          v-if="page === 'yzm' || page === 'register'"
          type="phone"
          v-model="phone"
          placeholder="手机号"
          clearable
        ></md-input-item>
        <md-input-item
          v-if="page === 'login'"
          v-model="password"
          type="password"
          placeholder="首次登录密码为身份证后6位"
          clearable
        ></md-input-item>
        <div class="yzm" v-if="page === 'yzm' || page === 'register'">
          <md-input-item type="text" maxlength="4" v-model="yzm" clearable placeholder="短信验证码"></md-input-item>
          <div
            class="yambtn"
            :style="yzmstyle"
            @click="getyzm"
            id="getyzm1"
          >{{djsbt?('('+ second+')'+yzmbtext):yzmbtext}}</div>
        </div>
        <md-button :loading="btnloading" :inactive="btnloading" @click="nhlogin">{{btntext}}</md-button>
        <div class="switch">
          <a class="switchlogin" @click="changetoyzm">{{yzmandpasswordtext}}</a>
          <a class="switchlogin" v-if="page !== 'register'" @click="changetoregister">注册账号</a>
        </div>
      </md-field>
    </div>
    </keep-alive>
    <md-field>
      <div class="xyword">
        <p class="frontpic">
          <img src="@/assets/nh/uncheck.png" alt v-if="!isxz" @click="isxz = !isxz" />
          <img src="@/assets/nh/check.png" alt v-else @click="isxz = !isxz" />
          <span @click="isxz = !isxz" class="readend">我已阅读并接受</span>
          <!-- <span
            @click="toshow('manment')"
          >《汇立天下会员管理制度》</span> -->
          <!-- <span
            @click="toshow('vipserve')"
          >《汇立天下会员服务协议》</span> -->
          <span
            @click="toshow('zcxy')"
          >《汇立天下注册协议》</span>
          <span
            class="zuihou"
            @click="toshow('statement')"
          >《汇立天下隐私服务协议》</span>
        </p>
      </div>
    </md-field>
    <md-landscape v-model="showFullScreen" full-screen class="landscape">
      <div class="pdf">
        <div>
          <pdf
            v-for="i in numPages"
            :key="i"
            ref="pdf"
            :page="i"
            :src="filepath"
            style="width: 100%; height: auto;"
            @num-pages="pageCount=$event"
          ></pdf>
        </div>
      </div>
    </md-landscape>
    <div class="closepdf" v-if="showFullScreen" @click="showFullScreen = !showFullScreen">关闭</div>
  </div>
</template>
<script>
import { Toast, InputItem } from 'mand-mobile'
import {
  getregisteryzm,
  register,
  getInit,
  getmsgloginyzm,
  yzmlogin,
  login
} from '@/api/basic'
import { setStorage, getStorage } from '@/lib/util'
import pdf from 'vue-pdf'
const LOGIN = Symbol('login')
const PHONE = Symbol('phone')
const PASSWORD = Symbol('password')
const YZM = Symbol('yzm')
const PAGE = Symbol('page')
export default {
  components: {
    pdf,
    [InputItem.name]: InputItem
  },
  data () {
    return {
      showFullScreen: false,
      filepath: '',
      pageCount: 0,
      numPages: 0,
      isxz: false,
      phone: '',
      phoneorempno: '',
      password: '',
      yzm: '',
      page: 'login',
      yzmbtext: '获取验证码',
      second: 59,
      djsbt: false,
      btntext: '登录',
      yzmandpasswordtext: '切换验证码登录',
      timer: null,
      yzmstyle: '',
      btnloading: false,
      httptype: ''
    }
  },
  // beforeRouteEnter (to, from, next) {
  //   if (getStorage('source', '') && getStorage('source', '') == 'qd') {
  //     sessionStorage.clear()
  //     history.go(-2)
  //     // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
  //     //   history.go(-3)
  //     // } else {
  //     //   history.go(-2)
  //     // }
  //     next(false)
  //   } else {
  //     next()
  //   }
  // },
  created () {
    this.httptype = window.location.hostname.split('.')[0]
  },
  mounted () {
    // if(window[PAGE]){
    //   this.page = window[PAGE]
    //   if(window[PAGE] == 'login'){
    //     this.phoneorempno = window[LOGIN] || ''
    //     this.password = window[PASSWORD] || ''
    //   }else{
    //     this.phone = window[PHONE] || ''
    //     this.yzm = window[YZM] || ''
    //   }
    // }
  },
  methods: {
    getyzm () {
      if (this.phone === '') {
        Toast.info('请填写手机号')
      } else {
        const PHONE = { phone: this.phone }
        const GET_YZM = {
          register: () => getregisteryzm(PHONE),
          yzm: () => getmsgloginyzm(PHONE)
        }
        GET_YZM[this.page]().then(res => {
          if (res.data.msg === '该手机号已被使用') {
            Toast.info('该手机号已被使用')
          } else {
            this.djsbt = true
            this.yzmbtext = '秒后重试'
            this.yzmstyle =
              'background-color:#C1BEC1;color:white;pointer-events:none'
            this.timer = setInterval(() => {
              this.second -= 1
              if (this.second === 0) {
                this.changeloginmethod()
              }
            }, 1000)
          }
        })
      }
    },
    changetoyzm () {
      this.btntext = '登录'
      if (this.yzmandpasswordtext === '切换密码登录') {
        this.yzmandpasswordtext = '切换验证码登录'
        this.changeloginmethod()
        this.page = 'login'
      } else {
        this.changeloginmethod()
        this.page = 'yzm'
        this.yzmandpasswordtext = '切换密码登录'
      }
    },
    changetoregister () {
      // this.page = 'register'
      // this.btntext = '注册'
      // this.changeloginmethod()
      this.$router.push({
        path: '/register',
        query: {
          page: 'register'
        }
      })
    },
    nhlogin () {
      if (!this.isxz) {
        Toast.info('请勾选同意相关协议')
        return false
      } else {
        if (this.phoneorempno === '' && this.page === 'login') {
          Toast.info('请填写工号或者手机号')
          return false
        }
        if (this.password === '' && this.page === 'login') {
          Toast.info('请填写密码')
          return false
        }
        if (this.phone === '' && this.page === 'yzm') {
          Toast.info('请填写手机号')
          return false
        }
        if (this.yzm === '' && this.page === 'yzm') {
          Toast.info('请填写正确验证码')
          return false
        }
        const form_list = { phone: this.phone, code: this.yzm }
        const form_login = {
          empno: this.phoneorempno,
          password: this.password,
          type: 'W'
        }
        const LOGIN_AND_REGISTER = {
          register: () => register(form_list),
          yzm: () => yzmlogin(form_list),
          login: () => login(form_login)
        }
        this.btnloading = true
        LOGIN_AND_REGISTER[this.page]()
          .then(res => {
            this.btnloading = false
            getInit().then(res => {
              let { ketel, kfqrcode, kfwechat } = res.data.data
              setStorage('u_s', res.data.data.user)
              setStorage('m_l', res.data.data.menulist)
              setStorage('ossurl', res.data.data.ossurl)
              setStorage('RSXZ', res.data.data.RSXZ)
              setStorage('comname', res.data.data.comname)
              setStorage('banner', res.data.data.banner)
              setStorage('bar', res.data.data.bar)
              setStorage('kfInfo', { ketel, kfqrcode, kfwechat })
              setStorage('qrcode', res.data.data.qrcode)
              setStorage('website', res.data.data.website)
              setStorage('flag', res.data.data.user.promotionprice)
              this.httptype == 'qd'
                ? this.$router.push({ path: '/main' })
                : this.$router.push({ path: '/' })
            })
          })
          .finally(() => {
            this.btnloading = false
          })
      }
    },
    changeloginmethod () {
      this.djsbt = false
      clearInterval(this.timer)
      this.yzmbtext = '获取验证码'
      this.second = 59
      // this.phone = ''
      this.password = ''
      this.yzm = ''
      this.yzmstyle =
        'background-color:#F4F1F2;color:#fb6822;pointer-events:auto'
    },
    toshow (file) {
      // this.numPages = 0;
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.filepath = pdf.createLoadingTask(file);
      //     this.filepath.then(pdf => {
      //       console.log(pdf);
      //       this.numPages = pdf.numPages;
      //     });
      //     this.showFullScreen = true;
      //   }, 500);
      // });
      // if(this.page == 'login'){
      //   window[LOGIN] = this.phoneorempno
      //   window[PASSWORD] = this.password
      //   window[PAGE] = 'login'
      // }else {
      //   window[PHONE] = this.phone
      //   window[YZM] = this.yzm
      //   window[PAGE] = 'yzm'
      // }
      this.$router.push(`/${file}`)
    }
  }
}
</script>
<style lang="stylus" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: white;
}

.logo {
  height: 28vh;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;

  img {
    width: 36vw;
    height: 11vw;
    margin-top: 100px;
  }
}

.switch {
  display: flex;
  justify-content: space-between;
}

.switchlogin {
  font-family: PingFang SC;
  color: rgba(56, 56, 56, 1);
  font-size: 38px;
}
/deep/ .md-field-item-content{
    background: #f2f2f2;
    margin: 36px 0;
    padding: 0 40px;
    border-radius: 12px;
  }
  /deep/.md-icon.icon-font.md{
      font-size:50px
    }
/deep/ .md-input-item-input {
  background: #f2f2f2;
  font-size: 40px;
  font-weight: bold
}
// /deep/ .md-input-item.is-android .md-input-item-input, .md-input-item.is-android .md-input-item-fake{
//   font-weight: bold;
// }
/deep/ .md-field-item-content::before {
  width: 0;
}

/deep/ .md-button {
  margin: 36px 0;
  background-color:color-primary;
  height: 120px;
  border-radius: 80px;
  color: white;
}

/* 验证码 */
/deep/ .yzm {
  display: flex;
  align-items: center;

  .md-field-item {
    position: relative;
    width: 75%;

    .md-input-item-input {
      border-radius: 12px 0 0 12px;
    }
  }

  .yambtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    width: 25%;
    height: 1rem;
    background-color: pink;
    border-radius: 0 12px 12px 0;
    background: #F4F1F2;
    color: color-primary;
    border-left: 1px solid lightgray;
  }
}

/* 协议 */
.xyword {
  display: flex;
  justify-content: center;

  // margin-top: 280px;

  .frontpic {
    img {
      width: 36px;
      height: 36px;
      position: relative;
      top: 0.04rem;
    }
    span {
      font-size: 34px;
      color: #108EE9;
      font-family: PingFang SC;
      margin: 0 2px;
    }
    .readend {
    font-size: 34px;
    font-family: PingFang SC;
    color: rgba(56, 56, 56, 1);
  }
  }
}

.closepdf {
  position: fixed !important;
  /* right: 0.25rem; */
  /* top: 0.25rem; */
  bottom: 0px !important;
  margin: 0 auto !important;
  width: 100vw !important;
  margin: auto !important;
  /* margin-left: 10vw; */
  height: 110px !important;
  background-color: color-primary !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white;
  z-index: 9999;
}

// /deep/ .md-icon-clear:before, .md-icon-fail:before {
//   content: '';
// }
</style>
